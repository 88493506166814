import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { mdiClose, mdiRefresh, mdiSend } from "@mdi/js";
import Icon from "@mdi/react";

const OverLay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  width: 50%;
  display: flex;
  gap: 1vh;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  border-style: solid;
  border-color: #4a6283;
  background-color: ${({ theme }) => "#fff"};
  padding: 0vh 1vh 1vh 1vh;
  min-width: 360px;
  overflow: scroll;
  height: 80%;

  p {
    font-size: 1.2vh;
    font-weight: bold;
  }
`;

const ImageFrame = styled.div`
  width: 100%;
  display: flex;
  border-style: solid;
  border-color: black;
  background-color: ${({ theme }) => "#fff"};
  padding: 1vh;
  color: #4a6283;
  gap: 1vh;
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-style: solid;
  border-color: black;
  background-color: ${({ theme }) => "#fff"};
  padding: 1vh;
  color: #4a6283;
  gap: 1vh;
`;

const Input = styled.input`
  flex-grow: 1;
  padding: 1vh;
  border: 1px solid #4a6283;
  border-radius: 4px;
`;

const SubmitButton = styled.button`
  padding: 1vh 2vh;
  background-color: #4a6283;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5vh;

  &:hover {
    background-color: #3a4f6a;
  }
`;
const InputGroup = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  gap: 1vh;
  margin-bottom: 1vh;
  button {
    width: 50%;
  }
`;
const CommandButton = styled(SubmitButton)`
  width: 100%;
  justify-content: center;
`;

const ButtonDiv = styled.div`
  display: flex;
  gap: 1vh;
`;

const ResponsiveGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1vh;
`;
const ResponsiveGridBtn = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(100%, 200px), 1fr));
  column-gap: 1vh;
`;

const LineContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: #4a6283;
`;

const TabletInterface = ({ item, onClick, admin }) => {
  const ip = item.status.serverIP;
  const ipSuffix = item.tabletIP.split(".")[3];

  const [brightness, setBrightness] = useState(0); // Default to middle value
  const [screenOrientation, setScreenOrientation] = useState("auto");
  const [showBooleanSettings, setShowBooleanSettings] = useState(false);

  const [imageUrl, setImageUrl] = useState(
    `https://server.edab.online/getImageFromFully?ip=${ipSuffix}&server=${ip}&port=2323`
  );

  const [urls, setUrls] = useState([
    { url: "http://192.168.4.1:3000/solo", label: "Retriever" },
    { url: "http://192.168.4.1:3000/tt360/manual", label: "TT360" },
    { url: "http://192.168.4.1:3000/master", label: "Master" },
    { url: "http://192.168.4.1:3000/getStorage", label: "Local storage" },
    {
      url: `http://192.168.4.1/index.php?box=${item.status.box}&lane=${item.status.lane}`,
      label: "Retriever old",
    },
    {
      url: `http://192.168.4.58:3000/`,
      label: "Developement",
    },
    { url: "Custom", label: "Custom" },
    // Add more URLs as needed
  ]);

  const screenOrientations = [
    { value: "0", label: "Auto" },
    { value: "1", label: "Portrait" },
    { value: "2", label: "Landscape" },
    { value: "3", label: "Reverse Portrait" },
    { value: "4", label: "Reverse Landscape" },
  ];

  const [selectedUrl, setSelectedUrl] = useState(urls[0]);
  const [inputMode, setInputMode] = useState(false);
  const [settings, setSettings] = useState(null);
  const [inputValues, setInputValues] = useState({
    loadUrl: "",
    setBooleanSettingKey: "",
    setBooleanSettingValue: "",
    setStringSettingKey: "",
    setStringSettingValue: "",
  });

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        let settings = await handleCommand("listSettings", { type: "json" });

        setSettings(settings);
        (await settings.response.screenBrightness) &&
          setBrightness(settings.response.screenBrightness);
        settings.response.forceScreenOrientation &&
          setScreenOrientation(settings.response.forceScreenOrientation);
      } catch (error) {
        console.error("Failed to fetch settings:", error);
      }
    };
    fetchSettings();

    const interval = setInterval(() => {
      setImageUrl((prevUrl) => {
        const newUrl = `https://server.edab.online/getImageFromFully?ip=${ipSuffix}&server=${ip}&port=2323&timestamp=${Date.now()}`;
        return newUrl;
      });
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  const toggleBooleanSettings = () => {
    setShowBooleanSettings(!showBooleanSettings);
  };

  const handleInputChange = (e, inputName) => {
    setInputValues({
      ...inputValues,
      [inputName]: e.target.value,
    });
  };

  const handleBrightnessChange = (newValue) => {
    setBrightness(newValue);
  };
  const handleBrightnessChangeComplete = () => {
    handleCommand("setStringSetting", {
      key: "screenBrightness",
      value: brightness.toString(),
    });
  };
  const handleOrientationChange = (newOrientation) => {
    setScreenOrientation(newOrientation);
    handleCommand("setStringSetting", {
      key: "forceScreenOrientation",
      value: newOrientation,
    });
  };

  const handleCommand = async (cmd, params = {}) => {
    try {
      const baseUrl = `https://server.edab.online/sendCommandToFully?ip=${ipSuffix}&server=${ip}&port=2323&command=${cmd}`;
      const url = Object.keys(params).reduce((acc, key) => {
        return `${acc}&${key}=${encodeURIComponent(params[key])}`;
      }, baseUrl);

      const response = await fetch(url, {
        method: "GET",

        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      return data;
      // Handle the response data as needed
    } catch (error) {
      console.error("Failed to fetch:", error);
      // Handle the error (e.g., show an error message to the user)
    }
  };

  return (
    <OverLay onClick={onClick}>
      <Container
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <LineContainer>
          <div style={{ display: "flex", gap: "2vh", alignItems: "center" }}>
            <h3>
              Tablet{" "}
              {item.status.box !== 1
                ? `Box: ${item.status.box} Lane: ${item.status.lane}`
                : item.status.lane}{" "}
            </h3>
          </div>
          <Icon path={mdiClose} size={2} onClick={onClick} />
        </LineContainer>
        <ImageFrame>
          <Image src={imageUrl} alt="Tablet screenshot" />
        </ImageFrame>

        <InputContainer>
          <ResponsiveGridBtn>
            <CommandButton onClick={() => handleCommand("loadStartUrl")}>
              Load Start URL
            </CommandButton>
            <div style={{ width: "100%", height: "1%" }}></div>
          </ResponsiveGridBtn>

          <InputGroup>
            {!inputMode ? (
              <select
                value={selectedUrl}
                onChange={(e) => {
                  if (e.target.value === "Custom") {
                    setInputMode(true);
                  } else {
                    setSelectedUrl(e.target.value);
                  }
                }}
                style={{
                  flexGrow: 1,
                  padding: "1vh",
                  border: "1px solid #4a6283",
                  borderRadius: "4px",
                }}
              >
                {urls.map((url, index) => (
                  <option key={index} value={url.url}>
                    {url.label}
                  </option>
                ))}
              </select>
            ) : (
              <Input
                type="text"
                value={inputValues.loadUrl}
                onChange={(e) => handleInputChange(e, "loadUrl")}
                placeholder="Enter URL..."
              />
            )}
            <ButtonDiv style={{ display: "flex", gap: "1vh" }}>
              <CommandButton
                onClick={() => handleCommand("loadUrl", { url: selectedUrl })}
              >
                Load URL
              </CommandButton>
              <CommandButton
                onClick={() =>
                  handleCommand("setStartUrl", { url: selectedUrl })
                }
              >
                Set Start URL
              </CommandButton>
            </ButtonDiv>
          </InputGroup>
        </InputContainer>

        {admin && (
          <>
            <InputContainer>
              <ResponsiveGrid>
                <InputGroup>
                  <h4>Screen Brightness: {Math.round(brightness / 2.55)}%</h4>
                  <input
                    type="range"
                    min="0"
                    max="255"
                    value={brightness}
                    onChange={(e) =>
                      handleBrightnessChange(parseInt(e.target.value))
                    }
                    onMouseUp={handleBrightnessChangeComplete}
                    onTouchEnd={handleBrightnessChangeComplete}
                    style={{
                      width: "100%",
                      maxWidth: "608px",
                      height: "25px",
                      backgroundColor: "#4a6283",
                      outline: "none",
                      opacity: "0.7",
                      transition: "opacity .2s",
                    }}
                  />
                </InputGroup>
                <InputGroup>
                  <h4>Screen Orientation:</h4>
                  <div
                    style={{
                      maxWidth: "608px",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      gap: "1vh",
                    }}
                  >
                    {screenOrientations.map((option) => (
                      <label
                        key={option.value}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "1vh",
                        }}
                      >
                        <input
                          type="radio"
                          name="screenOrientation"
                          value={option.value}
                          checked={screenOrientation === option.value}
                          onChange={() => handleOrientationChange(option.value)}
                          style={{ cursor: "pointer" }}
                        />
                        {option.label}
                      </label>
                    ))}
                  </div>
                </InputGroup>
              </ResponsiveGrid>
              <ResponsiveGridBtn>
                <CommandButton onClick={() => handleCommand("clearCache")}>
                  Clear Cache
                </CommandButton>
                <div style={{ width: "100%", height: "1%" }}></div>
              </ResponsiveGridBtn>

              <ButtonDiv>
                <CommandButton onClick={() => handleCommand("lockKiosk")}>
                  Kiosk Mode
                </CommandButton>

                <CommandButton onClick={() => handleCommand("unlockKiosk")}>
                  Kiosk Mode Off
                </CommandButton>
              </ButtonDiv>
              <ButtonDiv>
                <CommandButton
                  onClick={() => handleCommand("enableLockedMode")}
                >
                  Lock
                </CommandButton>

                <CommandButton
                  onClick={() => handleCommand("disableLockedMode")}
                >
                  Unlock
                </CommandButton>
              </ButtonDiv>

              <ButtonDiv>
                <CommandButton
                  onClick={() => {
                    if (
                      window.confirm(
                        "Are you sure you want to reset the tablet?"
                      )
                    ) {
                      handleCommand("clearWebstorage");
                    }
                  }}
                >
                  Reset Tablet
                </CommandButton>

                <CommandButton onClick={() => handleCommand("restartApp")}>
                  Restart App
                </CommandButton>
              </ButtonDiv>
            </InputContainer>
          </>
        )}

        <InputContainer>
          <CommandButton onClick={toggleBooleanSettings}>
            {showBooleanSettings
              ? "Hide Custom Settings"
              : "Show Custom Settings"}
          </CommandButton>

          {showBooleanSettings && (
            <>
              <InputGroup>
                <Input
                  type="text"
                  value={inputValues.setBooleanSettingKey}
                  onChange={(e) => handleInputChange(e, "setBooleanSettingKey")}
                  placeholder="Boolean setting key..."
                />
                <select
                  value={inputValues.setBooleanSettingValue}
                  onChange={(e) =>
                    handleInputChange(e, "setBooleanSettingValue")
                  }
                  style={{
                    flexGrow: 1,
                    padding: "1vh",
                    border: "1px solid #4a6283",
                    borderRadius: "4px",
                  }}
                >
                  <option value="">Select true or false</option>
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
                <SubmitButton
                  onClick={() => {
                    if (
                      window.confirm(
                        `Are you sure you want to set this boolean setting? ${inputValues.setBooleanSettingKey} to ${inputValues.setBooleanSettingValue}`
                      )
                    ) {
                      handleCommand("setBooleanSetting", {
                        key: inputValues.setBooleanSettingKey,
                        value: inputValues.setBooleanSettingValue,
                      });
                    }
                  }}
                >
                  Set Boolean Setting
                </SubmitButton>
              </InputGroup>

              <InputGroup>
                <Input
                  type="text"
                  value={inputValues.setStringSettingKey}
                  onChange={(e) => handleInputChange(e, "setStringSettingKey")}
                  placeholder="String setting key..."
                />
                <Input
                  type="text"
                  value={inputValues.setStringSettingValue}
                  onChange={(e) =>
                    handleInputChange(e, "setStringSettingValue")
                  }
                  placeholder="String setting value..."
                />
                <SubmitButton
                  onClick={() => {
                    if (
                      window.confirm(
                        `Are you sure you want to set this string setting? ${inputValues.setStringSettingKey} to ${inputValues.setStringSettingValue}`
                      )
                    ) {
                      handleCommand("setStringSetting", {
                        key: inputValues.setStringSettingKey,
                        value: inputValues.setStringSettingValue,
                      });
                    }
                  }}
                >
                  Set String Setting
                </SubmitButton>
              </InputGroup>
            </>
          )}
        </InputContainer>
      </Container>
    </OverLay>
  );
};

export default TabletInterface;
